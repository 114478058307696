import React from 'react';

import Link from '../atoms/link';
import Image from '../atoms/image';
import { H1, H2 } from '../atoms/header';
import { FeaturedHeader } from '../molecules/header';
import { P } from '../atoms/paragraph';
import { createMarkup } from '../../../utils';

export const HeroContainer = ({ contentHeader, contentFeatured, fullWidthCtas = false, ...props }) => {
  return (contentHeader && (
    <section className="hero-container">
      {
        contentHeader.title && <H1>{contentHeader.title}</H1>
      }
      {
        contentHeader.excerpt &&
        <div className={`hero-container__content-wrapper`}>
          {
            contentHeader.excerpt.map((paragraph, index) => (
              <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt" dangerouslySetInnerHTML={createMarkup(paragraph)} />
            ))
          }
        </div>
      }
      {
        contentHeader.ctas.map(({ to, href, icon, label, ...link }, index) => (
          <Link key={`${+new Date()}-${index}--link-cta`} icon={icon} className={`link--cta hero-container-link--cta`} to={to || href} alt={label} {...link}>{label}</Link>
        ))
      }
      <FeaturedHeader content={contentFeatured} fullWidthCtas={fullWidthCtas} />
    </section>
  )) || (<></>);
};

export const ContentContainer = ({ content, theme, isButtonBlock, ...props }) => {
  const isInvisibleClass = content && content.image && content.image.isInvisible ? 'link--btn-block' : '';
  const themeClass = theme === 'dark' ? ' content__container--dark' : '';
  const buttonBlockClass = isButtonBlock ? ` button__block` : ``;
  return (content && (
    <section className={`content__container ${themeClass}${buttonBlockClass}`}>
      {
        content.image &&
        (
          content.image.href ?
            (
              <>
                <Link
                  className={`link--image ${isInvisibleClass}`}
                  to={content.image.href}
                  target={content.image.target}
                  label={content.image.title}
                  title={content.image.title}
                  alt={content.image.title}>
                  <Image
                    src={content.image.src}
                    alt={content.image.title}
                    title={content.image.title}
                    isInvisible={content.image.isInvisible}
                    className="featured-header__image"
                    caption={content.image.caption} />
                  {
                    content.image.isInvisible && content.label && <H2 className={`link--btn-block-header`}>{content.label}</H2>
                  }
                </Link>
              </>
            ) : (
              <Image
                src={content.image.src}
                alt={content.image.title}
                title={content.image.title}
                className="featured-header__image"
                isInvisible={props.isInvisible}
                caption={content.image.caption} />
            )
        )
      }
      {
        content.tags && content.tags.length > 0 ?
          <div className="tags__wrapper">
            {
              content.tags.map((tagItem, index) => (
                <span className="tag-item" key={`tag-${index}`}>{tagItem}</span>
              ))
            }
          </div>
          :
          <></>
      }
      {
        content.title && <H1>{content.title}</H1>
      }
      {
        content.excerpt &&
        <div className="content__body">
          {
            content.excerpt && content.excerpt.map((paragraph, index) => (
              <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt" dangerouslySetInnerHTML={createMarkup(paragraph)} />
            ))
          }
        </div>
      }
      {
        content.list &&
        <ul>
          {
            content.list.map(({ icon, href, label, ...listItem }, index) => (
              <li key={`${+new Date()}-${index}--list-item`}>
                {
                  href ?
                    <Link icon={icon} to={href} alt={label} {...listItem} label={label}>{label}</Link>
                    :
                    <span>{label}</span>
                }
              </li>
            ))
          }
        </ul>
      }
      {
        content.links && content.links.map(({ label, href, ...itemProps }, index) => (
          <Link key={`${+new Date()}-${index}--link-link`} className="link--line" to={href} label={label} title={label} alt={label} {...itemProps}>{label}</Link>
        ))
      }
      {
        content.ctas && content.ctas.map(({ label, href, ...itemProps }, index) => (
          <Link key={`${+new Date()}-${index}--link-cta`} className="link--cta" to={href} label={label} title={label} alt={label} {...itemProps}>{label}</Link>
        ))
      }
    </section >
  )) || (<></>);
};

export const Tile = ({ content, theme, ...props }) => {
  return (
    <div className={`tile__wrapper`}>
      {
        content.image &&
        (
          content.image.href ?
            (
              <>
                <Link
                  className={`link--image`}
                  to={content.image.href}
                  target={content.image.target}
                  label={content.image.title}
                  title={content.image.title}
                  alt={content.image.title}>
                  <Image
                    src={content.image.src}
                    alt={content.image.title}
                    title={content.image.title}
                    isInvisible={content.image.isInvisible}
                    className="tile__image"
                    caption={content.image.caption} />
                  {
                    content.image.isInvisible && content.label && <H2 className={`link--btn-block-header`}>{content.label}</H2>
                  }
                </Link>
              </>
            ) : (
              <Image
                src={content.image.src}
                alt={content.image.title}
                title={content.image.title}
                className="tile__image"
                isInvisible={props.isInvisible}
                caption={content.image.caption} />
            )
        )
      }
    </div>
  );
};

export const ContentWrapper = ({ children, theme, ...props }) => {
  const themeClass = theme === 'dark' ? 'content__container--dark' : '';
  return (
    <section className={`content__container ${themeClass}`}>
      {children}
    </section>
  );
};