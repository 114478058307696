import React from 'react';

import Navigation from '../molecules/navigation';

const Footer = ({ title, navLinks }) => (
  <footer className="footer__wrapper">
    {
      title && <div className="footer-col">
        <span className="footer__label">
          ©{title} - {new Date().getFullYear()}
        </span>
      </div>
    }
    <div className="footer-col"><Navigation links={navLinks} /></div>
  </footer>
);

export default Footer; 
