import React from 'react';

import { createMarkup } from '../../../utils';

export const H1 = ({ type, children, className, ...props }) => {
  type = type && type === 'section' ? ' header__section' : '';
  return (
    <h1 className={`header ${className || ''}${type}`} {...props} dangerouslySetInnerHTML={createMarkup(children)} />
  );
}
export const H2 = ({ children, className, ...props }) => (<h2 className={`header ${className || ''}`} {...props} dangerouslySetInnerHTML={createMarkup(children)} />);
export const H3 = ({ children, className, ...props }) => (<h3 className={`header ${className || ''}`} {...props} dangerouslySetInnerHTML={createMarkup(children)} />);
