import React from 'react';

import Image from '../atoms/image';
import { Caption } from '../molecules/caption';

export const FeaturedHeader = ({ content, ...props }) => {
  return (
    <div className="featured-header__wrapper">
      <Image
        src={content.image.src}
        alt={content.image.title}
        title={content.image.title}
        className="featured-header__image"
        caption={content.image.caption} />
      <Caption content={content} {...props} />
    </div>
  );
};

const Header = ({ children, ...props }) => (
  <header {...props}>
    {children}
  </header>
);

export default Header;
