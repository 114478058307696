import React from 'react';

import { getPageData } from '../../../utils';

import SEO from '../atoms/seo';

import '../../../styles/index.scss';

import HOC from '../organisms/root';
import Grid, { GridRow, GridCol } from '../atoms/grid';
import { HeroContainer, ContentContainer } from '../molecules/container';
import MainNavigation from '../organisms/mainNavigation';
import { Devider, Spacer } from '../atoms/devider';
import { SpotlightContent } from '../molecules/spotlight';
import Header from '../molecules/header';
import Footer from '../molecules/footer';
// import { H1, H3 } from '../atoms/header';
// import Address from '../atoms/address';
// import { DefinitionList } from '../atoms/definitionList';

class CasePage extends React.Component {

  render() {
    const globalData = this.props.pageContext.data;
    const pageData = getPageData(this.props.pageContext.data, this.props.pageContext.type, this.props.pageContext.slug);
    return (
      pageData && pageData.data ?
        <HOC>
          <SEO {...pageData.data.seo} />
          <MainNavigation navLinks={globalData.mainNavLinks} pageType="case" />
          <Header className="is-case-page">
            <HeroContainer
              contentHeader={pageData.data.page.contentHeader}
              contentFeatured={pageData.data.page.contentFeatured}
              fullWidthCtas={true}
            />
          </Header>
          <Spacer spacing={2} />
          <Grid gap={20} noPadding={true} offset={true}>
            <GridRow direction="row">
              <GridCol colSize={7}>
                <Grid noPadding={true}>
                  <GridRow direction="column">
                    <GridCol>
                      <ContentContainer content={pageData.data.page.contentContainer.technology} />
                    </GridCol>
                  </GridRow>
                </Grid>
              </GridCol>
              <GridCol colSize={5} stretched={true}>
                <Grid>
                  <GridRow direction="column">
                    <GridCol>
                      <ContentContainer content={pageData.data.page.contentContainer.strategy} />
                    </GridCol>
                    <Devider margin={10} />
                    <GridCol>
                      <SpotlightContent content={pageData.data.page.spotlightContent} />
                    </GridCol>
                  </GridRow>
                </Grid>
              </GridCol>
            </GridRow>
          </Grid>
          <Grid gap={10} noPadding={true} offset={true}>
            <Devider margin={10} />
            <GridRow direction="row">
              <GridCol colSize={10}>
                <Footer title={globalData.footerTitle} navLinks={{ items: globalData.footerNavLinks.items }} />
              </GridCol>
              <GridCol colSize={2} align="right">
                <Footer navLinks={{ ctas: globalData.footerNavLinks.ctas }} />
              </GridCol>
            </GridRow>
          </Grid>
          <Spacer spacing={20} />
        </HOC>
        :
        <div>Loading</div>
    );
  }
};

export default CasePage;
